// @ts-nocheck
export const CoreEntitiesSurveySurveyType = {
  Unknown: 'Unknown',
  Guest: 'Guest',
  Member: 'Member',
  Entry: 'Entry',
  Exit: 'Exit',
  NetPromoterScore: 'NetPromoterScore',
  Mystery: 'Mystery',
  Associate: 'Associate',
  FoodBeverage: 'FoodBeverage',
  FiftyFifty: 'FiftyFifty',
  Event: 'Event',
  RoundsPlayed: 'RoundsPlayed',
  CaddieProgram: 'CaddieProgram',
  TroonShortGolf: 'TroonShortGolf',
  GolfOnly: 'GolfOnly',
  RetailOnly: 'RetailOnly',
  WomensGolfDay: 'WomensGolfDay',
  TourOrganizer: 'TourOrganizer',
  GolfSimulator: 'GolfSimulator',
  CustomSurvey: 'CustomSurvey',
  CustomSurveyNps: 'CustomSurveyNps',
  MemberGuestEvent: 'MemberGuestEvent',
  Players1stFeedback: 'Players1stFeedback',
  CustomSurveyNps2: 'CustomSurveyNps2',
  TroonOwnerSurvey: 'TroonOwnerSurvey',
  ConsultingNpsSurvey: 'ConsultingNpsSurvey',
  ConsultingNewCustomerSurvey: 'ConsultingNewCustomerSurvey',
  ConsultingChurnSurvey: 'ConsultingChurnSurvey',
  ConsultingCustomerSatisfactionSurvey: 'ConsultingCustomerSatisfactionSurvey',
  JuniorSurvey: 'JuniorSurvey',
  AffiliateSurvey: 'AffiliateSurvey',
  RangeSurvey: 'RangeSurvey',
  EmployeeSurvey: 'EmployeeSurvey',
  GAMemberSurvey: 'GAMemberSurvey',
  OKrunningSurvey: 'OKrunningSurvey',
  MemberInclusionSurvey: 'MemberInclusionSurvey',
  RangerSurvey: 'RangerSurvey',
  MensLeagueSurvey: 'MensLeagueSurvey',
  LadiesLeagueSurvey: 'LadiesLeagueSurvey',
  TroonOwnerSurveyV2: 'TroonOwnerSurveyV2',
  TroonOwnerMarketingSurvey: 'TroonOwnerMarketingSurvey',
} as const
export type CoreEntitiesSurveySurveyType =
  (typeof CoreEntitiesSurveySurveyType)[keyof typeof CoreEntitiesSurveySurveyType]
