// @ts-nocheck
export const CoreEntitiesIntegrationsSurveyIntegrationProviderEnum = {
  Unknown: 'Unknown',
  EZLinksBookings: 'EZLinksBookings',
  ChronoGolfBookings: 'ChronoGolfBookings',
  ClubProphetSystemsBookings: 'ClubProphetSystemsBookings',
  ForeUpBookings: 'ForeUpBookings',
  TeeOnBookings: 'TeeOnBookings',
  IBSBookings: 'IBSBookings',
  ClubLinkBookings: 'ClubLinkBookings',
  TrackManRangeBookings: 'TrackManRangeBookings',
  Quick18Bookings: 'Quick18Bookings',
  SGFGITBookings: 'SGFGITBookings',
  GolfBoxBookings: 'GolfBoxBookings',
  NexGolfBookings: 'NexGolfBookings',
  WiseGolfBookings: 'WiseGolfBookings',
  EGolf4UBookings: 'EGolf4UBookings',
  IntoGolfBookings: 'IntoGolfBookings',
  PCCaddieBookings: 'PCCaddieBookings',
  ClubInOneBookings: 'ClubInOneBookings',
  iGolfBookings: 'iGolfBookings',
  ClubCaddie: 'ClubCaddie',
  TeeControlBookings: 'TeeControlBookings',
  GolfManagerBookings: 'GolfManagerBookings',
  TrackManIndoorBookings: 'TrackManIndoorBookings',
  TeeOnCloudBookings: 'TeeOnCloudBookings',
  VlaanderenEntry: 'VlaanderenEntry',
  VlaanderenExit: 'VlaanderenExit',
  VlaanderenBooking: 'VlaanderenBooking',
  GolfBoxEntry: 'GolfBoxEntry',
  GolfBoxExit: 'GolfBoxExit',
  ForeTeesBookings: 'ForeTeesBookings',
  WhooshBookings: 'WhooshBookings',
  CPSApiBookings: 'CPSApiBookings',
  GolfManagerNewBookings: 'GolfManagerNewBookings',
  ExampleIntegrationBookings: 'ExampleIntegrationBookings',
  GolfBoxMember: 'GolfBoxMember',
  SGFGITMember: 'SGFGITMember',
  GolfGameBookMember: 'GolfGameBookMember',
  NGFMember: 'NGFMember',
  GolfBoxJuniorMember: 'GolfBoxJuniorMember',
  GolfGenius: 'GolfGenius',
  IDAConsulting: 'IDAConsulting',
  CapturiConsulting: 'CapturiConsulting',
  OKConsulting: 'OKConsulting',
  Miralix: 'Miralix',
  OkConsultingChurned: 'OkConsultingChurned',
  Economics: 'Economics',
} as const
export type CoreEntitiesIntegrationsSurveyIntegrationProviderEnum =
  (typeof CoreEntitiesIntegrationsSurveyIntegrationProviderEnum)[keyof typeof CoreEntitiesIntegrationsSurveyIntegrationProviderEnum]
